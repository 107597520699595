import Image from 'next/image';
import React, { useCallback, useEffect, useRef, useState } from 'react';

import { useGlobalContext } from '@/app/contexts/provider';
import { BasicButtonType, ButtonShape } from '@/app/components/molecules/basic-button';
import { isEmail } from '@/app/utils/regex';
import { Routes } from '@/enums/routes.enum';
import { ActivationErrorCode, LoginErrorCode } from '@/errors/serviceErrors/userServiceErrors';
import { useSearchParams } from 'next/navigation';
import { loginErrorMessages } from '../../utils/error-messages';
import InputIcon from '@/app/components/molecules/icon-form/input';
import Header from '../molecules/info-header';
import { useIsMobileScreen } from '@/app/hooks/useIsMobileScreen';
import { cn } from '@/app/utils/cn';
import { MAX_MOBILE_SCREEN_WIDTH } from '@/consts/max-size-limit';
import { expireCookie } from '@/app/utils/cookie-manager';
import SquareButton, { Color } from '@/app/components/atoms/square-button';
import { useModalManager } from '@/app/contexts/modalManagerContext';
import { useChatLabelStore } from '@/app/stores/useChatLabelStore';

const RightSideCredentials = () => {
    const { authService, navigationRouter } = useGlobalContext();
    const { showModal } = useModalManager('commons');
    const isMobileScreen = useIsMobileScreen();
    const labelStore = useChatLabelStore();

    const [isLoading, setIsLoading] = useState(false);
    const formRef = useRef<HTMLFormElement>(null);

    const [emailSent, setEmailSent] = useState<boolean>(false);
    const [emailSentViewProps, setEmailSentViewProps] = useState({
        title: '',
        text: ''
    });

    const params = useSearchParams();

    useEffect(() => {
        if (params && params.get('error') == 'true') {
            showModal('alert', {
                title: 'Error',
                messages: ['Ha ocurrido un error inesperado', 'Por favor, inténtelo más tarde.']
            });
        }
    }, [params]);

    useEffect(() => {
        if (!emailSent) return;
        navigationRouter.toAuthMessage(emailSentViewProps.title, emailSentViewProps.text);
    }, [emailSent]);

    const submitCredentials = useCallback(
        async (event: React.FormEvent<HTMLFormElement>) => {
            event.preventDefault(); //prevent the page from refreshing

            const credentials = {
                email: event.currentTarget.email.value,
                password: event.currentTarget.password.value
            };

            setIsLoading(true);
            const response = await authService.login(credentials.email, credentials.password);

            if (response.error) {
                const wasActivationError =
                    response.errorCode === LoginErrorCode.ACCOUNT_NOT_ACTIVATED;
                const modalConfig = {
                    title: 'Error al iniciar sesión',
                    messages: [
                        loginErrorMessages[response.errorCode as LoginErrorCode] || response.error
                    ]
                };

                if (wasActivationError) {
                    showModal('confirmation', {
                        ...modalConfig,
                        onConfirm: () =>
                            resendActivationToken(credentials.email, credentials.password),
                        onConfirmText: 'Reenviar mail'
                    });
                } else {
                    showModal('alert', modalConfig);
                }

                setIsLoading(false);
                return;
            }

            if (window.innerWidth < MAX_MOBILE_SCREEN_WIDTH) {
                expireCookie('Authorization');
                navigationRouter.to(Routes.NOT_SUPPORTED);
            }

            const redirectParam = params && params.get('redirect');
            const redirectRoute = redirectParam
                ? decodeURIComponent(redirectParam)
                : response.body.user.isAdmin
                  ? Routes.INDEX
                  : Routes.DASHBOARD;

            labelStore.clearAll(); //Needs to clear all labels when you change of client.
            navigationRouter.to(redirectRoute as Routes);
        },
        [params]
    );

    async function resendActivationToken(email: string, password: string) {
        setIsLoading(true);
        const response = await authService.sendActivationMail(email, password);

        if (response.error) {
            showModal('alert', {
                title: 'Error al reenviar mail',
                messages: [
                    loginErrorMessages[response.errorCode as ActivationErrorCode] || response.error
                ]
            });
        } else {
            setEmailSentViewProps({
                title: 'Valida tu e-mail',
                text: 'Te enviamos un link para que puedas validar la dirección de e-mail ingresada.\nPor favor, revisa tu bandeja de entrada y, si no encuentras nuestro mensaje, asegúrate de echar un vistazo a la carpeta de spam.'
            });
            setEmailSent(true);
        }
        setIsLoading(false);
    }

    async function handleForgotPassword() {
        if (formRef.current) {
            const email = formRef.current.email.value;
            if (isEmail(email)) {
                setIsLoading(true);
                await authService.sendPasswordResetMail(email);

                setEmailSentViewProps({
                    title: '¡Revisá tu casilla de e-mail!',
                    text: 'Te enviamos un link a tu e-mail para que puedas resetear tu contraseña.\nPor favor, revisa tu bandeja de entrada y, si no encuentras nuestro mensaje, asegúrate de echar un vistazo a la carpeta de spam.'
                });

                setIsLoading(false);
                setEmailSent(true);
            } else {
                showModal('alert', {
                    title: 'Error',
                    messages: ['Ingrese un email válido en el formulario para continuar']
                });
            }
        }
    }

    //TODO, encapsulate the form view.
    return (
        <div
            className={cn(
                'flex flex-1 items-center justify-center pt-[40px] px-[37px] ',
                isMobileScreen ? 'bg-dark-blue-bg' : 'bg-gray-background',
                'md:pt-[0px] md:h-full md:fixed md:w-1/2  md:right-0'
            )}
            style={
                isMobileScreen
                    ? {}
                    : {
                          backgroundImage: `url(/images/login-background.png)`,
                          backgroundRepeat: 'no-repeat',
                          backgroundSize: 'contain',
                          backgroundPosition: 'center'
                      }
            }
        >
            <div className={cn('flex flex-col items-center justify-center', '')}>
                {isMobileScreen && <Header className="mb-[14px]" />}
                <div
                    className={cn(
                        'flex flex-col rounded-[14px] items-center bg-white w-full max-w-[464px] h-auto px-[20px] pb-[16px] pt-[31px] shadow-form',
                        'md:mx-0 md:pt-[51px] md:pb-[25px] md:px-[32px]'
                    )}
                >
                    <Image
                        height={70}
                        width={280}
                        src="/images/delfi-logo.png"
                        alt="Delfi logo"
                        className="mb-[82px]"
                    />

                    <p className="text-base text-[12px] md:text-[16px] text-center gap-x-1 mb-[32px] md:mb-[32]">
                        <b className="text-black">¡Bienvenido! </b>
                        Ingresa tu mail para iniciar sesión
                    </p>

                    <form
                        ref={formRef}
                        className="w-full flex flex-col "
                        onSubmit={submitCredentials}
                    >
                        <div className="flex flex-col gap-y-[24px] md:gap-y-[37px]">
                            <InputIcon
                                required
                                name="email"
                                iconName={'email_outline'}
                                placeholder="E-mail"
                            />

                            <InputIcon
                                password
                                required
                                iconName={'lock_outline'}
                                name="password"
                                placeholder="Contraseña"
                            />
                        </div>
                        <div className="flex flex-col gap-y-[14px] mt-[52px] md:mt-[33px]">
                            <SquareButton
                                styleType={BasicButtonType.PRIMARY}
                                shape={ButtonShape.SQUARE}
                                text="INGRESAR"
                                loading={isLoading}
                                backgroundColor={Color.DARK_BLUE}
                                className="md:h-[72px] h-[44px]"
                                icon="/icons/chevron-right.svg"
                            />

                            <SquareButton
                                styleType={BasicButtonType.PRIMARY}
                                shape={ButtonShape.SQUARE}
                                text="REGISTRARSE"
                                enabled={!isLoading}
                                className="md:h-[72px] h-[44px]"
                                type="button"
                                icon="/icons/chevron-right.svg"
                                backgroundColor={Color.GREEN}
                                onClick={() => navigationRouter.to(Routes.REGISTER)}
                            />
                        </div>
                    </form>
                </div>

                <button
                    type="button"
                    className={cn(
                        'mt-[34px] text-[20px]',
                        isMobileScreen ? 'text-white' : 'text-black'
                    )}
                    onClick={handleForgotPassword}
                >
                    Olvidé mi <b>contraseña</b>
                </button>
            </div>
        </div>
    );
};

export default RightSideCredentials;
